import React from "react"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"
import HeaderAI from "../components/HeaderAI.jsx"
import Hero from "../components/HeroAI.jsx"
import Video from "../components/Video.jsx"
import UseCase from "../components/UseCase.jsx"
import MainFeature from "../components/MainFeature1.jsx"
import MainFeature1 from "../components/MainFeatureAI1.jsx"
import MainFeature2 from "../components/MainFeatureAI2.jsx"
import MainFeature3 from "../components/MainFeatureAI3.jsx"
import News from "../components/News.jsx"
import Contact from "../components/Contact.jsx"
import Footer from "../components/Footer.jsx"
import FAQ from "../components/FAQ.jsx"
import SEO from "../components/misc/SEO.jsx"

export default () => (
  <AnimationRevealPage disabled>
    <SEO title="AI導入とPoCをもっと手軽に！｜サクッとAI" />
    <HeaderAI />
    <MainFeature1 />
    <Contact />
    <FAQ service="ai" />
    <Footer />
  </AnimationRevealPage>
)
